import React, { useState, useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import theme from 'src/utils/styles/theme'
import YotpoStars from 'src/components/YotpoStars'
import Analytics from 'src/utils/Analytics'
import QuantitySelection from 'src/components/QuantitySelection'
import Frame2ColorSelection from 'src/components/Frame2ColorSelection'
import { GatsbyImage } from 'gatsby-plugin-image'
import Dropdown from 'src/components/Dropdown'
import {
  FramePriceByColor,
  GetFrameDiscountDetails,
  GetCtaDetails,
} from 'src/utils/FrameProductPage'

import { AmazonBuyNow, ExternalLink } from 'src/components/Hero/styles'
import {
  currencyCode,
  IS_FOREIGN_COUNTRY,
  HIDE_PLUS,
  IN_EU,
  LOCALIZATION,
  IN_UK,
} from 'src/utils/constants'
import Helmet from 'react-helmet'
import FramePickYourPrice from '../FramePickYourPrice'
import StoreContext from '../../context/StoreContext'
import AddToCartButton from '../AddToCartButton'
import ShippingDetails from '../ShippingDetails'
import FrameColorSelection from '../FrameColorSelection'
import Bogo from '../Bogo'
import {
  FRAME_VARIANTS,
  LIMITED_EDITION_VARIANTS,
  BUY_WITH_PRIME_SKU,
  BUY_WITH_PRIME_SKU_VARIANT_SKUS,
} from './constants'

import {
  ListRow,
  Header,
  Subheader,
  ButtonContainer,
  Centered,
  FrameProductOptions,
  FrameSelection,
  FrameSizeContainer,
  FramePrice,
  FramePriceStrike,
  PriceContainer,
  CustomizationOption,
  Text,
  BuyWithPrimeContainer,
  BlackFridayBubble,
  CtaContainer,
  Highlights,
  HighlightContainer,
  AmazonText,
} from './styles'
import StickyButton from '../StickyButton'
import { FormattedCurrency } from '../../utils/FormattedCurrency'
import { useStyleConfig } from '../../templates/SnapFramePage/styleConfig'
import { addReturnGuarantee } from '../../utils/addReturnGuarantee'

const defaultLanguage = process.env.GATSBY_I18N_DEFAULT_LANGUAGE

const FrameProductForm = ({
  product,
  simplifiedProductTitle,
  frame15,
  frameBlack,
  frameWhite,
  frameSilver,
  frameGold,
  framePoppy,
  handleSizeSelection,
  handleStyleSelection,
  isPlusProduct,
  setShownImageIndex,
  updateSelectedColor,
  size,
  style,
  color,
  frameWithPlus,
  frameWithoutPlus,
  returnGuaranteeId,
}) => {
  const { t } = useTranslation('common')

  const [allowBuyWithPrimeScript, setAllowBuyWithPrimeScript] = useState(false)
  const [buyWithPrimeLoaded, setBuyWithPrimeLoaded] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [selected, setSelected] = useState(false)
  useEffect(() => {
    if (!allowBuyWithPrimeScript) {
      setAllowBuyWithPrimeScript(true)
      return
    }

    // artificial await for buy with prime script
    const timeout = setTimeout(() => setBuyWithPrimeLoaded(true), 1000)
    return () => clearTimeout(timeout)
  }, [allowBuyWithPrimeScript])

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    updateIsMobile()
    window.addEventListener('resize', updateIsMobile)
    return () => window.removeEventListener('resize', updateIsMobile)
  }, [])

  const {
    checkmark,
    contentful10FramePrice,
    contentful15FramePrice,
    buyWithPrimeData,
    contentfulCTAOverrides,
    link,
    amazonButtonData,
    amazonIntlButtonData,
  } = useStaticQuery(graphql`
    query FrameProductForm {
      contentful10FramePrice: allContentful10InFramePriceStrikethrough {
        edges {
          node {
            en_original_price
            en_discounted_price
            en_discount_bubble
            en_original_price_White
            en_discounted_price_White
            en_discount_bubble_White
            en_original_price_Silver
            en_discounted_price_Silver
            en_discount_bubble_Silver
            en_original_price_Gold
            en_discounted_price_Gold
            en_discount_bubble_Gold
            en_original_price_Poppy
            en_discounted_price_Poppy
            en_discount_bubble_Poppy
            en_ca_original_price
            en_ca_discounted_price
            en_ca_discount_bubble
            en_au_original_price
            en_au_discounted_price
            en_au_discount_bubble
            en_gb_original_price
            en_gb_discounted_price
            en_gb_discount_bubble
            en_eu_original_price
            en_eu_discounted_price
            en_eu_discount_bubble
          }
        }
      }
      contentful15FramePrice: allContentful15InFramePriceStrikethrough {
        edges {
          node {
            en_original_price
            en_discounted_price
            en_discount_bubble
            en_ca_original_price
            en_ca_discounted_price
            en_ca_discount_bubble
            en_au_original_price
            en_au_discounted_price
            en_au_discount_bubble
            en_gb_original_price
            en_gb_discounted_price
            en_gb_discount_bubble
            en_eu_original_price
            en_eu_discounted_price
            en_eu_discount_bubble
          }
        }
      }
      buyWithPrimeData: allContentfulBuyWithPrime {
        edges {
          node {
            Black
            Black_15
            Gold
            Poppy
            Silver
            White
            show_sticky_cta
            default_frame_product
            show_bwp_cta_first
          }
        }
      }
      amazonButtonData: allContentfulAmazonButton {
        edges {
          node {
            shownColors
            url_Black
            url_Gold
            url_Poppy
            url_Silver
            url_White
          }
        }
      }
      contentfulCTAOverrides: allContentfulCtaCopyOnFrameAndCalendarPdp {
        edges {
          node {
            en_10_frame_Black
            en_10_frame_Gold
            en_10_frame_Poppy
            en_10_frame_Silver
            en_10_frame_White
            en_15_frame_Black
            en_au_10_frame
            en_au_15_frame
            en_ca_10_frame
            en_ca_15_frame
            en_eu_10_frame
            en_eu_15_frame
            en_gb_10_frame
            en_gb_15_frame
          }
        }
      }
      checkmark: file(relativePath: { eq: "frame2/checkmark-pdp.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FIXED, placeholder: NONE, width: 16)
        }
      }
      link: file(relativePath: { eq: "external-link-black.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      amazonIntlButtonData: allContentfulIntlAmazonButton {
        edges {
          node {
            shown_countries
            en_au_10
            en_au_15
            en_ca_10
            en_ca_15
            en_eu_10
            en_eu_15
          }
        }
      }
    }
  `)

  const frame15Price = frame15.data.shopifyProduct.variants[0].price

  const [frame10Price, setFrame10Price] = useState(frameBlack.data.shopifyProduct.variants[0].price)
  const BUY_WITH_PRIME_INFO = buyWithPrimeData.edges[0].node
  const default10frameColor =
    BUY_WITH_PRIME_INFO.default_frame_product === '15" Black'
      ? 'Black'
      : BUY_WITH_PRIME_INFO.default_frame_product
  const showBwpCtaFirst = BUY_WITH_PRIME_INFO.show_bwp_cta_first

  const [selectedColor, setSelectedColor] = useState(color)
  const [selectedSecondColor, setSelectedSecondColor] = useState(color)

  const { shownColors, url_Black, url_Gold, url_Poppy, url_Silver, url_White } =
    amazonButtonData.edges[0].node

  const { shown_countries: shownCountries } = amazonIntlButtonData.edges[0].node
  const countryKey = LOCALIZATION.replace(/-/g, '_')
  const intlAmzLink = amazonIntlButtonData.edges[0].node[`${countryKey}_${size}`]
  const showAmazonBuyButton =
    (shownColors.includes(selectedColor) && size === '10' && !IS_FOREIGN_COUNTRY) ||
    shownCountries.includes(LOCALIZATION)
  const amazonUrlMap = {
    Black: url_Black,
    Gold: url_Gold,
    Poppy: url_Poppy,
    Silver: url_Silver,
    White: url_White,
  }
  const AMAZON_LINK =
    !IS_FOREIGN_COUNTRY && showAmazonBuyButton && amazonUrlMap[selectedColor] !== 'NONE'
      ? amazonUrlMap[selectedColor]
      : IS_FOREIGN_COUNTRY
      ? intlAmzLink
      : 'https://urlgeni.us/amazon/100DA'
  const [showStickyButton, setShowStickyButton] = useState(true)
  function isElementVisible(el) {
    if (!el) return false
    const rect = el.getBoundingClientRect()
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const windowWidth = window.innerWidth || document.documentElement.clientWidth
    const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0
    const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0
    return vertInView && horInView
  }

  const [selectedSku, setSelectedSku] = useState(
    size === 15 ? BUY_WITH_PRIME_SKU[15].Black : BUY_WITH_PRIME_SKU[10][selectedColor]
  )

  const DISPLAY_BUY_WITH_PRIME =
    size === 15 ? BUY_WITH_PRIME_INFO.Black_15 : BUY_WITH_PRIME_INFO[selectedColor]

  const frame10Product = {
    Black: frameBlack.data.shopifyProduct?.variants[0],
    Silver: frameSilver.data.shopifyProduct?.variants[0],
    White: frameWhite.data.shopifyProduct?.variants[0],
    Gold: frameGold.data.shopifyProduct?.variants[0],
    Poppy: framePoppy.data.shopifyProduct?.variants[0],
  }

  const frame10Titles = {
    Black: frameBlack.data.shopifyProduct?.title,
    Silver: frameSilver.data.shopifyProduct?.title,
    White: frameWhite.data.shopifyProduct?.title,
    Gold: frameGold.data.shopifyProduct?.title,
    Poppy: framePoppy.data.shopifyProduct?.title,
  }

  const frameProduct = {
    10: frame10Product[selectedColor],
    15: frame15.data.shopifyProduct.variants[0],
    '10F2': isPlusProduct
      ? frameWithPlus?.variants?.[0] || null
      : frameWithoutPlus?.variants?.[0] || null,
  }

  const productName = {
    10: frame10Titles[selectedColor],
    15: frame15.data.shopifyProduct.title,
    '10F2': isPlusProduct ? frameWithPlus?.title : frameWithoutPlus?.title,
  }

  const productTitle = {
    10: 'The Skylight Frame',
    15: 'The Skylight Frame 15',
    '10F2': ' Skylight Frame 2',
  }

  const productInfo = {
    frame: frameProduct[size],
    name: productName[size],
    other: product.variants[0],
    frame2: frame10Product[selectedSecondColor],
  }

  const [isFixed, setIsFixed] = useState(true)
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 900 || window.pageYOffset < 150) {
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
      const regularButton = document.querySelector('.regular-cta')
      const regularButtonVisible = isElementVisible(regularButton)
      setShowStickyButton(!regularButtonVisible)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const {
    store: { cart },
    addToCart,
  } = useContext(StoreContext)

  const [quantityVal, setQuantity] = useState(1)
  const [submitting, setSubmitting] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState('with-plus')

  useEffect(() => {
    if (!IS_FOREIGN_COUNTRY && buyWithPrimeLoaded) {
      try {
        window?.bwp?.updateWidget(selectedSku, quantityVal, BUY_WITH_PRIME_SKU_VARIANT_SKUS)
      } catch (error) {
        console.warn('Buy with prime update failed.', error)
      }
    }
  }, [selectedSku, quantityVal])

  useEffect(() => {
    isPlusProduct(false)
  }, [selectedProduct, isPlusProduct])

  // const priceRef = useRef(null)
  // const scrollToPrice = () => priceRef.current.scrollIntoView()
  // const setSelectedProductAndScroll = (product) => {
  //   setSelectedProduct(product)
  //   scrollToPrice()
  // }
  // const setInitialNumFramesAdded = (q) => {
  //   let initialFrameQuantity = 0
  //   const existingFrameLineItem = cart.lines.edges.find(
  //     (el) => el.node.merchandise.id === productInfo.shopifyId
  //   )
  //   if (existingFrameLineItem) {
  //     initialFrameQuantity = existingFrameLineItem.node.quantity
  //   }
  //   LocalStorage.setItem('originalFrameQuantity', initialFrameQuantity + q)
  // }

  const fireAnalytics = (deviceSize) => {
    Analytics.track(`Viewed ${deviceSize} Toggle Product Page`)
  }

  const fireframeColorAnalytics = (color) => {
    Analytics.track(
      `Viewed ${size} ${size === '10F2' ? style : ''} ${color} Frame ${
        size === '10F2' ? '2 ' : ''
      }Toggle Product Page`
    )
  }

  useEffect(() => {
    if (!IS_FOREIGN_COUNTRY && simplifiedProductTitle === 'Frame') {
      window.addEventListener('blur', () => {
        window.setTimeout(() => {
          if (document.activeElement instanceof HTMLIFrameElement) {
            Analytics.track(`Added ${size} ${selectedColor} Frame with bwprime to Cart`)
          }
        }, 0)
      })
    }
  }, [])

  const handleAddToCart = async (variantId, quantity) => {
    const id = variantId.split('/').pop()
    const pushToDataLayer = () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode,
          add: {
            products: [
              {
                id,
                name: productInfo.name,
                category: 'Frame',
                price: productInfo.frame.price,
                quantity,
              },
            ],
          },
        },
      })
    }

    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added ${simplifiedProductTitle} to Cart`)
      if (simplifiedProductTitle === 'Frame') {
        const eventLabel =
          size === '10F2'
            ? `Added ${style} ${selectedColor} 10" Frame 2 to Cart`
            : `Added ${selectedColor} ${size}" Frame to Cart`
        Analytics.track(eventLabel)
      }
      // setInitialNumFramesAdded(quantity)
      let items = [{ variantId, quantity }]
      if (!IS_FOREIGN_COUNTRY && selectedProduct === 'with-plus' && size === '10F2') {
        Analytics.track(`Added Plus to Cart`)
        if (quantity > 1) {
          items = [
            { variantId: frameWithPlus.variants[0].shopifyId, quantity: 1 },
            { variantId: frameWithoutPlus.variants[0].shopifyId, quantity: quantity - 1 },
          ]
        }
      }
      const resp = await addToCart(addReturnGuarantee(items, cart, returnGuaranteeId))

      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        pushToDataLayer(variantId, productInfo, quantity)
        if (simplifiedProductTitle !== 'Frame' || HIDE_PLUS || size === '10F2') {
          navigate('/cart/')
        } else {
          navigate('/products/skylight-plus/')
        }
      }
    }
  }
  const incrementQuantity = () => {
    setQuantity(quantityVal + 1)
    if (!IS_FOREIGN_COUNTRY && buyWithPrimeLoaded) {
      try {
        window?.bwp?.updateWidget(selectedSku, quantityVal + 1, BUY_WITH_PRIME_SKU_VARIANT_SKUS)
      } catch (error) {
        console.warn('Buy with prime update failed.', error)
      }
    }
  }
  const decrementQuantity = () => {
    if (quantityVal === 1) {
      return
    }
    setQuantity(quantityVal - 1)
    if (!IS_FOREIGN_COUNTRY && buyWithPrimeLoaded) {
      try {
        window?.bwp?.updateWidget(selectedSku, quantityVal - 1, BUY_WITH_PRIME_SKU_VARIANT_SKUS)
      } catch (error) {
        console.warn('Buy with prime update failed.', error)
      }
    }
  }

  const DisplayBWP = !IS_FOREIGN_COUNTRY
  const styleConfig = useStyleConfig()
  const [stylesOrder, setStylesOrder] = useState(Object.keys(styleConfig))

  // Strikethrough Price for 10" & 15" Frame is managed in contentful
  const lang = defaultLanguage.replace('-', '_')
  const frame10Prices = contentful10FramePrice.edges[0].node
  const frame15Prices = contentful15FramePrice.edges[0].node

  const frame10DiscountDetails = GetFrameDiscountDetails(
    frame10Prices,
    lang,
    selectedColor,
    selectedColor !== 'Black'
  )
  const frame15DiscountDetails = GetFrameDiscountDetails(frame15Prices, lang, selectedColor, false)
  const is10Discounted = frame10DiscountDetails.isDiscounted
  const orig10Price = frame10DiscountDetails.origPrice
  const orig10F2prices = {
    Classic: '169.99',
    Cover: '169.99',
    Wood: '199.99',
    'Shadow Box': '199.99',
  }
  const orig10F2Price =
    styleConfig[style]?.prices?.[color]?.originalPrice === 'NONE'
      ? orig10F2prices[style]
      : styleConfig[style]?.prices?.[color]?.originalPrice

  const disc10F2Price =
    styleConfig[style]?.prices?.[color]?.discountedPrice !== 'NONE'
      ? styleConfig[style]?.prices?.[color]?.discountedPrice
      : orig10F2Price

  const disc10F2BubbleCopy =
    styleConfig[style]?.prices?.[color]?.discountBubble !== 'NONE'
      ? styleConfig[style]?.prices?.[color]?.discountBubble
      : styleConfig[style]?.prices?.[color]?.discountBubble

  const isFrame2Discounted =
    styleConfig[style]?.prices?.[color]?.discountedPrice <
    styleConfig[style]?.prices?.[color]?.originalPrice

  let overridenPrice = {}
  if (isFrame2Discounted) {
    overridenPrice = {
      originalPrice: styleConfig[style]?.prices?.[color]?.originalPrice,
      discountedPrice: styleConfig[style]?.prices?.[color]?.discountedPrice,
      discountedPriceWithPlus: styleConfig[style]?.prices?.[color]?.discountedPrice - 10,
    }
  }

  const disc10Price = frame10DiscountDetails.discPrice
  const disc10BubbleCopy = frame10DiscountDetails.discBubbleCopy

  const is15Discounted = frame15DiscountDetails.isDiscounted
  const orig15Price = frame15DiscountDetails.origPrice
  const disc15Price = frame15DiscountDetails.discPrice
  const disc15BubbleCopy = frame15DiscountDetails.discBubbleCopy

  const ctaDetails = GetCtaDetails(
    lang,
    false,
    size === 15 ? 15 : 10,
    contentfulCTAOverrides.edges[0].node,
    selectedColor
  )
  const { isPreorder, isOutOfStock, CTA_VALUE } = ctaDetails

  const handleColorSelection = (variant) => {
    fireframeColorAnalytics(variant)
    setSelectedColor(variant)
    setShownImageIndex(0)
    // setSelectedSku(BUY_WITH_PRIME_SKU[10][variant])
    // if (!IS_FOREIGN_COUNTRY && buyWithPrimeLoaded) {
    //   try {
    //     window?.bwp?.updateWidget(
    //       BUY_WITH_PRIME_SKU[10][variant],
    //       quantityVal,
    //       BUY_WITH_PRIME_SKU_VARIANT_SKUS
    //     )
    //   } catch (error) {
    //     console.warn('Buy with prime update failed.', error)
    //   }
    // }
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // })
  }

  const handleSecondColorSelection = (variant) => {
    setSelectedSecondColor(variant)
  }

  const scrollableStylesContainerRef = useRef(null)

  const handleStyleSelectionScroll = (selectedStyle) => {
    if (style !== 'Wood' && selectedStyle === 'Wood') {
      setSelectedColor('Walnut')
    } else if (style !== 'Shadow Box' && selectedStyle === 'Shadow Box') {
      setSelectedColor('Charcoal')
    } else {
      setSelectedColor('Black')
    }
    setShownImageIndex(0)
    handleStyleSelection(selectedStyle)
    Analytics.track(`Viewed 10F2 Frame ${selectedStyle} Toggle Product Page`)
    if (isMobile) {
      const reorderedStyles = [
        selectedStyle,
        ...stylesOrder.filter((styleKey) => styleKey !== selectedStyle),
      ]
      setStylesOrder(reorderedStyles)
      if (scrollableStylesContainerRef.current) {
        scrollableStylesContainerRef.current.scrollTo({
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  useEffect(() => {
    updateSelectedColor(selectedColor)
    if (!IS_FOREIGN_COUNTRY) {
      const frameOptions = {
        Poppy: framePoppy,
        Black: frameBlack,
        Silver: frameSilver,
        White: frameWhite,
        Gold: frameGold,
      }
      const price = FramePriceByColor(selectedColor, frameOptions)
      setFrame10Price(price)
    }
  }, [
    updateSelectedColor,
    selectedColor,
    framePoppy,
    frameBlack,
    frameSilver,
    frameWhite,
    frameGold,
  ])

  const internationalframeOptions = [
    {
      sizeKey: '10',
      size: '10 inch',
      isSelected: size === '10',
      isDiscounted: size !== '10' && !IS_FOREIGN_COUNTRY ? true : is10Discounted,
      originalPrice: size !== '10' && !IS_FOREIGN_COUNTRY ? '159.99' : orig10Price,
      discountedPrice: size !== '10' && !IS_FOREIGN_COUNTRY ? '139.99' : disc10Price,
      regularPrice: size !== '10' && !IS_FOREIGN_COUNTRY ? '159.99' : frame10Price,
      bubbleCopy: size !== '10' && !IS_FOREIGN_COUNTRY ? '$20 OFF' : disc10BubbleCopy,
      fireAnalyticsLabel: '10in Frame',
      selectedColor: IS_FOREIGN_COUNTRY ? 'Black' : default10frameColor,
      selectedSku: BUY_WITH_PRIME_SKU[10].White,
      bubbleFifteen: false,
      list: [
        'Beautiful touchscreen display',
        'Easy for All Ages to use',
        'Holds thousands of photos',
      ],
      style: 'Classic',
    },
    {
      sizeKey: '15',
      size: '15 inch',
      isSelected: size === '15',
      isDiscounted: is15Discounted,
      originalPrice: orig15Price,
      discountedPrice: disc15Price,
      regularPrice: frame15Price,
      bubbleCopy: disc15BubbleCopy,
      fireAnalyticsLabel: '15in Frame',
      selectedColor: 'Black',
      selectedSku: BUY_WITH_PRIME_SKU[15].Black,
      bubbleFifteen: is15Discounted,
      list: [
        'Stunning widescreen touchscreen display',
        'Easy for All Ages to use',
        'Holds thousands of photos',
      ],
      style: 'Classic',
    },
  ]
  const usaFrameOptions = [
    ...internationalframeOptions,
    {
      sizeKey: '10F2',
      size: '10 inch Frame 2',
      isSelected: size === '10F2',
      isDiscounted: size === '10F2' ? false : isFrame2Discounted,
      originalPrice: size === '10F2' ? orig10F2Price : '199.99',
      discountedPrice: size === '10F2' ? disc10F2Price : 'NONE',
      regularPrice: size === '10F2' ? orig10F2Price : '199.99',
      bubbleCopy: disc10F2BubbleCopy,
      fireAnalyticsLabel: '10in Frame 2',
      selectedColor: 'Walnut',
      selectedSku: BUY_WITH_PRIME_SKU[10].Black,
      bubbleFifteen: isFrame2Discounted,
      list: ['Effortless touchscreen display', 'Interchangeable Frames', 'Full HD'],
      style: 'Wood',
    },
  ]
  const frameOptions = IS_FOREIGN_COUNTRY ? internationalframeOptions : usaFrameOptions
  const selectedProductDetails = frameOptions.find((option) => option.sizeKey === size)
  const frameOriginalPrice =
    frameOptions.find((option) => option.sizeKey === size)?.originalPrice || null
  const show10FrameColorOptions = !IS_FOREIGN_COUNTRY && size === '10'

  const showBogoDeal = false

  const frameOGPrice = {
    en: { Black: 159.99, White: 159.99, Silver: 159.99, Gold: 159.99, Poppy: 169.99 },
    'en-ca': { Black: 199.99 },
    'en-gb': { Black: 159 },
    'en-au': { Black: 249 },
    'en-eu': { Black: 149 },
  }
  const frameDiscountedPrice = {
    en: { Black: 79.5, White: 79.5, Silver: 79.5, Gold: 79.5, Poppy: 89.5 },
    'en-ca': { Black: 119.99 },
    'en-gb': { Black: 95 },
    'en-au': { Black: 149 },
    'en-eu': { Black: 104 },
  }
  const bogoDealDetails = {
    en: {
      dealTitle: 'Bundle & Save',
      description: {
        10: 'Mix and match any two 10" Frames. Buy one, get one 50% off!',
        15: 'Mix and match with a 10" Frame. Buy one, get a 10” Frame 50% off!',
      },
    },
    'en-eu': {
      dealTitle: 'OR Buy One, Get One',
      description: {
        10: 'Buy one 10” Frame for yourself, at full price and gift a second to a loved one for <b>30% off</b>.  Offer expires soon.',
      },
    },
  }

  return (
    <>
      {!IS_FOREIGN_COUNTRY && allowBuyWithPrimeScript && (
        <Helmet>
          <script
            defer
            src="https://code.buywithprime.amazon.com/default.variant.selector.shopify.js"
          />
          <script async src="https://code.buywithprime.amazon.com/bwp.v1.js" />
        </Helmet>
      )}
      <Centered>
        <YotpoStars color={theme.gold} size={18} productId="7073751498804" />
      </Centered>
      <Header>
        <Trans>{productTitle[size]}</Trans>
      </Header>
      {!IS_FOREIGN_COUNTRY && isMobile ? (
        <>
          <Dropdown
            title="Pick a size"
            size={size}
            handleSizeSelection={handleSizeSelection}
            setSelectedColor={setSelectedColor}
            handleStyleSelection={handleStyleSelection}
            frameOptions={frameOptions}
          />
        </>
      ) : (
        <CustomizationOption>
          <Subheader>
            <Trans>Pick a size</Trans>
          </Subheader>
          <FrameProductOptions>
            {frameOptions.map((option) => (
              <FrameSelection
                key={option.sizeKey}
                wide={is10Discounted || is15Discounted}
                blue={option.isSelected}
                onClick={() => {
                  handleSizeSelection(option.sizeKey)
                  fireAnalytics(option.fireAnalyticsLabel)
                  setSelectedColor(option.selectedColor)
                  setSelectedSku(option.selectedSku)
                  setShownImageIndex(0)
                  handleStyleSelection(option.style)
                }}
              >
                <FrameSizeContainer>
                  <Text>
                    <Trans>{option.size}</Trans>
                  </Text>
                </FrameSizeContainer>
                {option.isDiscounted ? (
                  <PriceContainer>
                    <FramePriceStrike>{FormattedCurrency(option.originalPrice)}</FramePriceStrike>
                    <FramePrice red>{FormattedCurrency(option.discountedPrice)}</FramePrice>
                  </PriceContainer>
                ) : (
                  <FramePrice>{FormattedCurrency(option.regularPrice)}</FramePrice>
                )}
                {option.bubbleCopy && option?.bubbleCopy !== 'NONE' && (
                  <BlackFridayBubble
                    isSelected={option.isSelected}
                    blue={option.bubbleCopy === 'NEW'}
                  >
                    {option.bubbleCopy}
                  </BlackFridayBubble>
                )}
              </FrameSelection>
            ))}
          </FrameProductOptions>
          {!IS_FOREIGN_COUNTRY && selectedProductDetails && (
            <HighlightContainer>
              {selectedProductDetails.list.map((detail, index) => (
                <ListRow key={detail}>
                  <GatsbyImage
                    image={checkmark.childImageSharp.gatsbyImageData}
                    alt="Gray Checkmark"
                  />
                  <Highlights key={index}>{detail}</Highlights>
                </ListRow>
              ))}
            </HighlightContainer>
          )}
        </CustomizationOption>
      )}
      {show10FrameColorOptions && (
        <FrameColorSelection
          IS_FOREIGN_COUNTRY={IS_FOREIGN_COUNTRY}
          FRAME_VARIANTS={FRAME_VARIANTS}
          LIMITED_EDITION_VARIANTS={LIMITED_EDITION_VARIANTS}
          selectedColor={selectedColor}
          handleColorSelection={handleColorSelection}
          contentfulCTAOverrides={contentfulCTAOverrides.edges[0].node}
        />
      )}
      {size === '10F2' && (
        <Frame2ColorSelection
          image=""
          style={style}
          color={selectedColor}
          handleStyleSelection={handleStyleSelectionScroll}
          handleColorSelection={handleColorSelection}
          styleConfig={styleConfig}
          stylesOrder={stylesOrder}
          scrollableStylesContainerRef={scrollableStylesContainerRef}
          OOS={false}
          hideQuanity
          quantityVal={quantityVal}
          decrementQuantity={decrementQuantity}
          incrementQuantity={incrementQuantity}
          submitting={submitting}
          handleAddToCart={handleAddToCart}
          shopifyId=""
          plp
        />
      )}
      {!isOutOfStock && size !== '10F2' && (
        <QuantitySelection
          quantityVal={quantityVal}
          decrementQuantity={decrementQuantity}
          incrementQuantity={incrementQuantity}
        />
      )}
      {showBogoDeal && (
        <Bogo
          FRAME_VARIANTS={FRAME_VARIANTS}
          LIMITED_EDITION_VARIANTS={LIMITED_EDITION_VARIANTS}
          selectedSecondColor={selectedSecondColor}
          handleSecondColorSelection={handleSecondColorSelection}
          contentfulCTAOverrides={contentfulCTAOverrides}
          selected={selected}
          setSelected={setSelected}
          dealDetails={{
            key: '10Frame',
            dealTitle: bogoDealDetails[LOCALIZATION]?.dealTitle || 'OR Buy One, Get One',
            description:
              bogoDealDetails[LOCALIZATION]?.description?.[size] ||
              'Buy one 10” Frame for yourself, at full price and gift a second to a loved one for <b>40% off</b>. Offer expires soon.',
            offer: size === '15' ? 'Add a 10" Frame' : 'Add another Frame',
            offerDescription:
              size === '15'
                ? `Buy 15" Frame and get a 10" Frame ${
                    !IS_FOREIGN_COUNTRY && selectedSecondColor !== 'Poppy'
                      ? '50%'
                      : selectedSecondColor === 'Poppy'
                      ? '47%'
                      : '40%'
                  } off.`
                : `Buy one Frame and get your second ${
                    IN_EU
                      ? '30%'
                      : !IS_FOREIGN_COUNTRY && selectedSecondColor !== 'Poppy'
                      ? '50%'
                      : selectedSecondColor === 'Poppy'
                      ? '47%'
                      : '40%'
                  } off.`,
            originalPrice: frameOGPrice[LOCALIZATION][selectedSecondColor],
            discountedPrice: frameDiscountedPrice[LOCALIZATION][selectedSecondColor],
            sale: `${
              IN_EU
                ? '30%'
                : !IS_FOREIGN_COUNTRY && selectedSecondColor !== 'Poppy'
                ? '50%'
                : selectedSecondColor === 'Poppy'
                ? '47%'
                : '40%'
            } OFF`,
          }}
          frame
        />
      )}
      {size !== '10F2' && (
        <CtaContainer showBwpCtaFirst={showBwpCtaFirst}>
          <ButtonContainer className="regular-cta">
            <AddToCartButton
              label={t(CTA_VALUE)}
              adding={submitting}
              handleClick={() => handleAddToCart(productInfo.frame.shopifyId, quantityVal)}
              oos={isOutOfStock}
            />
          </ButtonContainer>
          {DisplayBWP && (
            <BuyWithPrimeContainer
              visible={DISPLAY_BUY_WITH_PRIME}
              isFixed={isFixed && BUY_WITH_PRIME_INFO.show_sticky_cta}
            >
              <div
                id="amzn-buy-now"
                data-site-id="pe1h2oqj31"
                data-widget-id="w-PycpPvXHix3e1fXLJFDNc4"
                data-sku={selectedSku}
              />
            </BuyWithPrimeContainer>
          )}
        </CtaContainer>
      )}
      {size === '10F2' && (
        <FramePickYourPrice
          productInfo={productInfo}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          frameOriginalPrice={frameOriginalPrice}
          frameWithPlus={frameWithPlus}
          frameWithoutPlus={frameWithoutPlus}
          overridenPrice={overridenPrice}
          quantityVal={quantityVal}
          onAddToCart={handleAddToCart}
          submitting={submitting}
        />
      )}
      {showStickyButton &&
        size !== '10F2' &&
        !IS_FOREIGN_COUNTRY &&
        isMobile &&
        !isOutOfStock &&
        (!BUY_WITH_PRIME_INFO.show_sticky_cta ||
          (BUY_WITH_PRIME_INFO.show_sticky_cta && !DISPLAY_BUY_WITH_PRIME)) && (
          <StickyButton
            label={t('Add to Cart')}
            onClick={() => handleAddToCart(productInfo.frame.shopifyId, quantityVal)}
            isVisible={showStickyButton}
            oos={isOutOfStock}
          />
        )}
      {!IS_FOREIGN_COUNTRY && !isOutOfStock && !isPreorder && <ShippingDetails frame />}
      {IN_UK && <ShippingDetails frame />}
      {showAmazonBuyButton && AMAZON_LINK !== 'NONE' && (
        <CtaContainer>
          <AmazonText>
            <Trans>Also available on Amazon</Trans>
          </AmazonText>
          <ButtonContainer>
            <AmazonBuyNow href={AMAZON_LINK} target="_blank" rel="noopener noreferrer">
              <Trans>Buy Now on Amazon</Trans>
              <ExternalLink image={link.childImageSharp.gatsbyImageData} alt="external link" />
            </AmazonBuyNow>
          </ButtonContainer>
        </CtaContainer>
      )}
    </>
  )
}

FrameProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  simplifiedProductTitle: PropTypes.string.isRequired,
  title: PropTypes.string,
  frame15: PropTypes.object.isRequired,
  frameBlack: PropTypes.object.isRequired,
  frameWhite: PropTypes.object.isRequired,
  frameSilver: PropTypes.object.isRequired,
  frameGold: PropTypes.object.isRequired,
  framePoppy: PropTypes.object.isRequired,
  handleSizeSelection: PropTypes.func.isRequired,
  handleStyleSelection: PropTypes.func.isRequired,
  isPlusProduct: PropTypes.func.isRequired,
  setShownImageIndex: PropTypes.func.isRequired,
  updateSelectedColor: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  frameWithPlus: PropTypes.object.isRequired,
  frameWithoutPlus: PropTypes.object.isRequired,
  returnGuaranteeId: PropTypes.string,
}

export default FrameProductForm
